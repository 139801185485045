.editor-page {
  .editor-menu-placeholder {
    padding-left: 0;
    padding-right: 0;
  }

  .editor-container-placeholder {
    padding-left: 0;
    padding-right: 0;
  }

}