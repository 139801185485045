.rule-submenu {

  position: absolute;
  top: 19px;
  right: 15px;
  
  button {
    color: #003591;
    border: 1px solid #003591;
    padding:0 9px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active,
    &:focus,
    &:hover,
    &:active:focus, {
      background-color: #ccecf8;
    }

    &:first-child{
      margin-right: 16px;
    }
  }

  
  span.glyphicon.glyphicon-plus {
    top:0;
    margin-left:8px;
  }

  span.glyphicon.glyphicon-menu-hamburger {
    font-size: 1.2em;
  }

  .dropdown-menu {
    border: 1px solid #009fda;
    border-radius: 0;
    padding: 0;
    font-size: 16px;

    .custom-icon {
      margin-right: 5px;
    }

    li {
      border-bottom: 1px solid #ccc;
      
      &:last-child {
        border-bottom: none;
      }
      
      a {
        font-weight: 600;
        color: #003591;
        padding: 10px 12px;

        &:hover {
          background-color: #ccecf8;
        }

        input {
          font-weight: normal;
          color: #999;
          padding: 10px 12px 5px 0px;
        }
      }
    }
  } 

  .inputWrapper {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-family: Avenir Next,Verdana,Geneva,sans-serif;
    /*Using a background color, but you can use a background image to represent a button*/
    
    font-weight: 600;
    color: #003591;
    padding: 6px 12px;
    
    &:hover {
      background-color: #ccecf8;
    }

    span {
      margin-right: 10px !important;
    }
  }

.fileInput {
    cursor: pointer;
    height: 100%;
    position:absolute;
    top: 0;
    right: 0;
    /*This makes the button huge so that it can be clicked on*/
    font-size: 50px;
}
  
}

.btn-default:active, .btn-default.active, 
.open > .btn-default.dropdown-toggle,
.btn-default:active:hover,
.open > .btn-default.dropdown-toggle:hover  {
  background-color: #009fda !important;
  color: #fff !important;
  box-shadow: none;
  border-color: #009fda;
}