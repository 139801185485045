@font-face {
  font-family: 'DNV Display Medium';
  src: url('../static/fonts/DNVDisplay-Medium-Web.woff2') format('woff2'), /* Super Modern Browsers */
       url('../static/fonts/DNVDisplay-Medium-Web.woff') format('woff'); /* Super Modern Browsers */
}

//$icon-font-path: "./src/scss/dnvgl-styles-1.0.92-source/source/fonts/bootstrap/";
$icon-font-path: "//bootstrap-sass/assets/fonts/bootstrap/";
//$relative-path: 'dnvgl-styles-1.0.92-source/source/';
$relative-path: '//';
@import "./dnvgl-styles-1.0.92-source/source/sass/dnvgl-styles-bundled";
@import "./common.scss"
