.modal-header {
    padding: 10px 20px 10px;
    background-color: #ccecf8;

    .close {
    margin-top: 4px;
}
}

.modal-body {
    padding: 20px;
}

.img-prev {
  width: 50px;
  height: 50px;
  border: 2px dotted #ccc;
  display: block;
  float: left;
  img {
    width: 50px;
    height: 50px;
  }

}

.img-upload {
  margin-left: 65px;
}