
// SETTINGS
@import '../settings/spacing-variables';


/*
 *
 *
 * -----------------------------------------------------------------------------
 *  GRID
 * -----------------------------------------------------------------------------
 *
 *  Modifies the standard grid gutter to be 2 x 16 = 32 pixels instead of the
 *  default 15 pixels in Bootstrap. This aligns with our base 2 spacing system.
 *
 *  Modifiers for the Bootstrap grid, for Comfortable, Condensed and Narrow
 *  column spacing. Uses BEM notation, clearly separating our extensions from
 *  the default Bootstrap grid elements.
 *
 */

$column-spans: (
  one:  1,      two:   2,      three:   3,      four:    4,
  five: 5,      six:   6,      seven:   7,      eight:   8,
  nine: 9,      ten:  10,      eleven: 11,      twelve: 12
);

$column-breakpoints: (
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg'
);


// .row.row-narrow {
//   margin: 0 (- $space-xs);
//
//   @each $span-name, $col-count in $column-spans {
//     @each $bp-name, $bp-size in $column-breakpoints {
//       .col-#{$bp-name}-#{$col-count} {
//         padding: 0 $space-xs;
//       }
//     }
//   }
// }

.row.row-compact {
  margin: 0 (- $space-xs * 1.5);

  @each $span-name, $col-count in $column-spans {
    @each $bp-name, $bp-size in $column-breakpoints {
      .col-#{$bp-name}-#{$col-count} {
        padding: 0 ($space-xs * 1.5);
      }
    }
  }
}

// .row-comfortable {
//   margin: 0 (- $space-md * 1.5);
//
//   @each $span-name, $col-count in $column-spans {
//     @each $bp-name, $bp-size in $column-breakpoints {
//       .col-#{$bp-name}-#{$col-count} {
//         padding: 0 ($space-md * 1.5);
//       }
//     }
//   }
// }

// .row-luxurious {
//   margin: 0 (- $space-lg);
//
//   @each $span-name, $col-count in $column-spans {
//     @each $bp-name, $bp-size in $column-breakpoints {
//       .col-#{$bp-name}-#{$col-count} {
//         padding: 0 ($space-lg);
//       }
//     }
//   }
// }
