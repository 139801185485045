
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';
@import '../settings/text-variables';

// MIXINS
@import '../mixins/clearfix';
@import '../mixins/rwd-mixins';
@import '../mixins/spacing-mixins';

small, // TODO: Document usage of <small> tag.
.small,
.text-small {  // TODO: remove .text-small?
  font-size: .875em !important;  // 16px -> 14px
}

.text-smaller {
  font-size: .75em !important;  // 16px -> 12px
}


/*
 *
 *  TEXT APPEARANCE
 *  ---------------
 */

.text-condensed {
  line-height: $dnvgl-line-height-condensed !important;
  // line-height: 1.15em !important;
}

.text-light {
  font-weight: normal !important;
}

.text-bold {
  font-weight: 700 !important;
}

// TODO: Consider.
// Use the text Broader view font
.text-font-broader-view {
  font-family: 'Broader View', sans-serif !important;
}

// TODO: Consider! Do we need this? Now has specific link classes for this?
.text-undecorated,
.text-undecorated:hover,
.text-undecorated:focus,
.text-undecorated:active,
*:hover  .text-undecorated,
*:focus  .text-undecorated,
*:active .text-undecorated {
  text-decoration: none !important;

  * {
    text-decoration: none !important;
  }
}


/*
 *
 *  MATHEMATIC FORMULAS
 *  -------------------
 */

// Denotes a mathematical fragment of text.
// The font is changed to better render greek letters,
// as the Avenir Next Brand font does not do this in a good way.
.text-mathfrag {
  font-family: 'Times New Roman', serif !important;
  font-size: 1.15em;  // TODO: Evaluate!
}


/*
 *
 *  BOOTSTRAP TEXT HELPERS
 *  ----------------------
 */

// Added '!important' to these to make them real trumps.

// Alignment
.text-left           { text-align: left !important; }
.text-right          { text-align: right !important; }
.text-center         { text-align: center !important; }
.text-justify        { text-align: justify !important; }
.text-nowrap         { white-space: nowrap !important; }

// Transformation
.text-lowercase      { text-transform: lowercase !important; }
.text-uppercase      { text-transform: uppercase !important; }
.text-capitalize     { text-transform: capitalize !important; }

// Contextual colors
.text-muted {
  color: color(neutral-40) !important;
}


// TODO: Add all the old helpers to the helper page!!
