
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';
@import '../settings/text-variables';

// MIXINS
@import '../mixins/clearfix';
@import '../mixins/rwd-mixins';
@import '../mixins/spacing-mixins';

/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BACKGROUND COLORS
 * -----------------------------------------------------------------------------
 *  Helper classes for background colors.
 *  NOTE: Do not over-use. Consider if color should be part of a class that
 *  defines and styles the block in question rather than using helper classes.
 *
 */

@each $name, $color in $dnvgl-colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}
