html,
body {
 
  .app {
    color: #222;

      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .app-content-holder {
        position: relative;
        margin-top: 15px;
      }
  }
}

