
// SETTINGS
@import '../settings/dnvgl-color-settings';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BADGES
 * -----------------------------------------------------------------------------
 *
 */

.badge-default {
  background-color: color(neutral-60);
}

.badge-primary {
  background-color: color(dark-blue);
}

.badge-success {
  background-color: color(land-green-light);
}

.badge-info {
  background-color: color(cyan);
}

.badge-warning {
  background-color: color(orange);
}

.badge-danger {
  background-color: color(red);
}
