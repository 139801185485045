

/**
 *
 *
 * -----------------------------------------------------------------------------
 *  TYPE
 * -----------------------------------------------------------------------------
 *
 */


p {
  line-height: 1.5;
  margin: 0 0 .75rem;   // TODO: Convert into variables?

  &:last-child {
    margin: 0;
  }
}
