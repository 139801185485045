.editor-area {
  // > div {
  //   border: none;
  //   border-radius: 0;
  // }

  .editor-tabs {

    .tab-content {
      border: 1px solid #ddd;
      border-top: none;
      padding: 8px;
        // border-left: 1px solid #ddd;
        // border-right: 1px solid #ddd;
        // border-bottom: 1px solid #ddd;

      }
      
    .nav-tabs {
      li {
        a {
          //padding: 10px 19px 8px;
          padding: 8px;
          font-size: 16px;
          top: 1px;
        }
      }

      .active  {
          background-color: #fff;
          }
    }
  }
}