
// SETTINGS
@import '../settings/dnvgl-color-settings';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  HEADER
 * -----------------------------------------------------------------------------
 *
 */

.header-dnvgl {
  margin: 1rem 0 0;

  .navbar-toggle {
    .icon-bar {
      background-color: color(dark-blue);
    }
  }
}

.site-title {
  color: color(dark-blue);
  display: block;
  font-family: 'Broader View';
  font-size: 1.125rem;
  margin: 0;
  text-decoration: none;
}

.header-nologo {
  margin: 2rem 0 0;
}
