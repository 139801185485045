
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';

// MIXINS
@import '../mixins/spacing-mixins';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BLOCKS
 * -----------------------------------------------------------------------------
 *
 */



/**
 *
 *
 * -----------------------------------------------------------------------------
 *  FEEDBACKS
 * -----------------------------------------------------------------------------
 *  Alert boxes built on the Bootstrap standard alerts.
 *
 */

.alert {
  border-radius: 0;
}

.close {
  // Compensate for the new default font size
  line-height: .9;
  text-decoration: none;
}

.alert-danger {
  background-color: color(red-lightest);
  border-color: color(red-lightest);
  color: color(red-darkest);

  .close {
    color: color(red-darkest);
  }

}

.alert-info {
  background-color: color(sky-blue);
  border-color: color(primary-white);
  color: color(dark-blue);

  .close {
    color: color(dark-blue);
  }
}

.alert-success {
  background-color: color(land-green-lightest);
  border-color: color(land-green-lightest);
  color: color(land-green-darkest);

  .close {
    color: color(land-green-darkest);
  }
}

.alert-warning {
  background-color: color(yellow-lightest);
  border-color: color(yellow-lightest);
  color: color(yellow-darkest);

  .close {
    color: color(yellow-darkest);
  }
}
