.pass-header {
  .site-title {
    text-decoration: none;
    font-family: 'DNVDisplay-Medium-Web', Arial, sans-serif;
  }

  nav.temporary {
    // border-bottom: 1px solid #f2f2f2;
    min-height: 10px;
  }

  .log-out-menu {
    padding: 0;
    min-width: 110px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li a {
        display: block;
        padding: 10px;
        text-decoration: none;
     
        &:hover {
          background-color: #ccecf8;
        }
      }
    }
  }

  .logo {
    height: 60px;
    width: 160px;
    
    .logo-image {
      display: block;
      height: auto;
      width: 100%;
    }
  } 
  
}
