.rule-results {
  font-size: 18px;

  h3 {
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0px 15px 0px;
    font-weight: bold;
    margin: 0px 0px 20px;
  }

  .result-item {
    width: 100%;
    margin-top: 10px;
    padding: 13px;
    min-height: 50px;
    height: auto;
    overflow: hidden;
  
    .block-name {
      border-radius: 20px;
      padding: 3px 10px;
      text-transform: uppercase;
      background-color: #e98300;
      color: #fff;
      font-size: 12px;
      height: 22px;
      margin-right: 10px;
      font-weight: bold;
      float: left;
    }

    .result-note {
      .block-name {
        background-color: #009fda;
      }
    }
  }
 
  .result-warning {
    background-color: #f7cf99;
    border: 1px solid #e98300;

    .result-text  {
      float: left;
      width: 84%;
      padding-left: 8px;
    }
  }
  
  .result-note {
    background-color: #e5f5fb;
    border: 1px solid #009fda;
  }

  

  
}