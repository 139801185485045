/*!
┌─────────────────────────────────────────────────────────────────────────┐
│ DNV GL STYLES BUNDLE                                                    │
│ Contains the DNV GL Styles CSS and Bootstrap 3 in one optimized bundle. │
└─────────────────────────────────────────────────────────────────────────┘ */

// Minified file is optimized during build using CSSO:
// https://github.com/css/csso
//
// This process cleans (removing redundancies), compresses (replaces with
// shorter form) and restructures (merge of declarations, rulesets and so on).
//
// As the DNV GL styles overrides and extend default Bootstrap styles, this
// cleans out a lot of Bootstrap styles that are overriden and not in use.
// The result is an optimized, minified CSS which is much much smaller.

@import 'bootstrap';
@import 'dnvgl-styles';
