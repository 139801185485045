.editor-menu {
  padding-right: 10px;

  .search-input-group {
    width: 100%;
  }

   .form-group {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    // padding-bottom: 18px;
    // margin: 3px 0px 0px;
    // width: 89%;
    margin-bottom: 0;
  }
  
  .menu-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      height: 100%;
      //margin-right: 25px;

      li {
        
        border-bottom: 1px solid #ccc;
        padding: 4px 5px 8px;
        height: auto;
        overflow: hidden;

        .right-side {
          //width: 60px;
          padding: 5px;
          border: 1px solid black;
          float: right;
        
        }

        .glyphicon-title {
          margin-right: 4px;
          color: #36842d;
        }

        .left-side {
          float: left;
        }

      }

      span.name {
        font-size: 18px;
        font-weight: 600;
      }

      .workingCopy {
        background-color: #f2f2f2;
      }

      .current {
        background-color: #e5f5fb;

        .info {
          color: #999;
        }

        .btn-subtle{
          border-color: #0067c5;
        }
      }


      .info {
        margin-bottom: 2px;
        font-size: 12px;
        color: #ccc;
        line-height: 16px;
      }

      .functions {
        font-size: 14px;
        font-weight: 600;
        margin-top: 5px;
      }

      button {
        margin-right: 5px;
      }

      .btn-xs {
        padding: 2px 2px 1px 5px;
        font-size: 12px;
    }

      .btn-active {
        border-color: #36842d;
        color: #36842d;
        background-color: #fff;

        a {
        color: #36842d;
      }

        &:hover{
          background-color: #bae6b0;
        }
      }

      
      .functions a {
        text-decoration: none;
        margin-right: 4px;
        border-right: 1px solid #0067c5;
  
        &:last-child {
          border: none;
        }
      }
      
      
    }
}