.rule_box {
  position: relative;
  padding: 0px;
	border: 5px solid #ccc;
	width: 290px;
	height: 250px;
	float: left;
	margin: 0px 40px 30px 0px;

  img {
      width: 100%;
      position: relative;
      max-height: 144px;
  }

  .rule-title {
      padding: 10px 15px 3px;
      font-family: 'avenirdemi', sans-serif;
      font-size: 18px !important;
      color: #003591;
      margin-bottom: 0px;
  }

  .rule-desc {
    padding: 0px 15px;
    font-size: 18px !important;
  }

  .use-rule {
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-weight: normal;
  }
}
