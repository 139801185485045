
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';
@import '../settings/text-variables';  // TODO: Remove here? Clean out unneeded imports.

// MIXINS
@import '../mixins/rwd-mixins';
@import '../mixins/spacing-mixins';
@import '../mixins/icon-mixins';
// TODO: Update to use spacing system!!

/**
 *
 *
 * -----------------------------------------------------------------------------
 *  NAVIGATION
 * -----------------------------------------------------------------------------
 *
 */

.navbar-dnvgl {
  background-color: color(primary-white);
  border-bottom: 9px solid color(neutral-05);
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  min-height: 30px;
  position: relative;
  z-index: 10;

  @include xsmall {
    background-color: color(primary-white);
    border: 0;
    border-top: 1px solid color(neutral-05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    min-height: 0;
    position: absolute;
    width: 100%;

    .in {
      padding-bottom: 1rem;
    }
  }



  .nav-underlay {
    background-color: rgba(0, 0, 0, .5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .dropdown {
    position: static;
  }

  .container > .navbar-collapse {
    margin: 0 -15px;

    @include xsmall {
      margin: 0;
    }
  }

  .navbar-nav > li {
    margin-bottom: -9px;

    @include xsmall {
      margin: 0;
    }

    > a {
      @include xsmall {
        border: 0;
        margin: 0;
      }

      border-bottom: 9px solid transparent;
      color: color(dark-blue);
      line-height: 1.75rem;
      margin: 0 2rem 0 0;
      min-height: 1.75rem;
      padding: 0;
      text-decoration: none;

      &.active {
        background-color: transparent;
        border-bottom-color: color(dark-blue);
      }

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        border-bottom-color: color(sky-blue);
        color: color(dark-blue);
        outline: 0;
        box-shadow: none;
      }
    }

    &.open a {
      background-color: transparent;
      border-bottom-color: color(sky-blue);
    }

    &.active>a {
      border-bottom-color: color(dark-blue);
      color: color(dark-blue);
      font-weight: 700;

      &:hover,
      &:focus,
      &:active {
        border-bottom-color: color(sky-blue);
      }
    }

    .dropdown-menu {
      border: 0;
      margin: 9px 0 1rem;
      padding: 1rem 0;
      width: 100%;
      // TODO: Ketil: Moved all @includes to the top, but not nice for breakpoints.
      // Turn this rule off in the linter?
      @include xsmall {
        margin: 0;
        padding: 0;

        h1 {
          margin: 0;
        }
      }

      ul {
        list-style: none;
        padding: 0;

        @include xsmall {
          margin: 0 0 .5rem;
        }

      }
      li {
        a {
          font-size: 1rem;
          border: 0;
          color: color(dark-blue);
          line-height: 1.5rem;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.navbar-dnvgl-dark {
  background-color: color(dark-blue);
  border: 0;
  border-radius: 0;
  color: color(primary-white);

  @include xsmall {
    padding: 0 0 1rem;
  }

  > .navbar {
    margin: 0;
  }

  .dropdown {
    position: static;
  }

  .navbar-nav > li {
    margin: 0;

    > a {
      font-weight: 700;
      line-height: 1rem;
      margin-right: -1px;
      min-height: 1rem;
      padding: .75rem 1.3125rem .75rem 1.25rem;  // Compensate for -1px margin
      text-transform: uppercase;

      &:hover,
      &:focus,
      &:active {
        background-color: color(neutral-20);
        color: color(dark-blue);
        outline: 0;
      }
    }

    &.open a {
      background-color: color(neutral-10);
      color: color(dark-blue);
    }

    .dropdown-menu {
      background-color: color(neutral-10);
      border: 0;
      box-shadow: none;
      color: color(dark-blue);
      margin: 0;
      padding: 1rem 0;
      width: 100%;

      @include xsmall {
        border: 0;
        margin: 0;
        padding: 0;

        h1 {
          margin: 0;
        }
      }
    }
  }
  .navbar-nav > li:not(:first-child) > a::before {
    border-left: 1px solid color(neutral-20);
    bottom: 12px;
    content: '';
    display: inline-block;
    height: auto;
    left: 0;
    position: absolute;
    top: 12px;
  }
}

.navbar-profile-offset-md {
  margin-top: -55px;
}

.navbar-profile {
  position: relative;
  float: right;


  @include xsmall {
    margin: 0 -15px;
    float: none;
    padding: .5rem 0;
    border-bottom: 1px solid color(neutral-10);
  }

  .dropdown-menu {
    border: 1px solid color(neutral-40);
    border-top: 4px solid color(digital-blue);
    border-radius: 0;
    padding: 1rem;

    @include xsmall {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }

}

 

.navbar-veracity {
   @extend .navbar-dnvgl;
   box-shadow: none;

    .veracity-space-stack{
        margin-bottom:30px;
     }
  .right-icons{
    float:right;
  }
  .veracity-logo{
    color:color(dark-blue);
    text-decoration: none;
    cursor:pointer;
    font-size:2rem;
  }

  .navbar-toggle{
    position: relative;
    float: right;
    margin-right: 15px;
    padding:0;
    margin-top: 4px;
    margin-bottom: 8px;
    margin-left:8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    width:25px;
    height:25px;

    & .icon-bar{
        position: absolute;
        display: block;
        top:50%;
        width:25px;
        transition: transform 0.2s ease-out;
        &:first-child{
           transform: translate3d(0, -4px, 0);
        }
        &:nth-child(2){
           transform: translate3d(0, -2px, 0)  scaleX(1);
        }
        &:last-child{
           transform: translate3d(0, 4px, 0);
        }
    }

    &:not(.collapsed){
      .icon-bar{
        position: absolute;
        display: block;
        top:50%;
        width:25px;
        transition: transform 0.2s ease-out;
        &:first-child{
           transform: translate3d(0, 0, 0) rotate(45deg);
        }
        &:nth-child(2){
           transform: translate3d(0, -4px, 0)  scaleX(0.1);
        }
        &:last-child{
           transform: translate3d(0, -4px, 0) rotate(-45deg);
        }
      }
    }


    &:after{
      content:'MENU';
      position:absolute;
      bottom: -15px;
      left: -6px;
      color:color(dark-blue);
      font-size: 12px;
      transform:scale(.7);
      font-weight: bold;
    }
  }

  .split-mark{
     font-size: 32px;
     border-left:1px solid color(neutral-40);
     margin:0 15px;
  }
  .text-login{
    color:color(dark-blue);
    cursor: pointer;
    line-height: 3;

  }

&.active>a {
      border-bottom-color: color(dark-blue);
      color: color(dark-blue);
      font-weight: 700;

      &:hover,
      &:focus,
      &:active {
        border-bottom-color: color(sky-blue);
      }
    }

  .nav>li{
      &>a:hover,focus,active,&.active a:hover,focus,active{
        background-color: transparent;
        border-bottom-color: color(dark-blue);
        color: color(dark-blue);
        outline: 0;
        box-shadow: none;
      }
  }
  .veracity-navbar-header{
    a{
      color:color(dark-blue);
      text-decoration: none;
      b{
        font-size:2rem;
        text-transform: uppercase;
      }
      sub{
        font-size: 1.125rem;
        display: block;
        font-family: Avenir Next,Verdana,Geneva,sans-serif;
      }
    }
     
  }

  .veracity-auth-user-menu{
      ul{
        list-style: none;
          .col-sm-1 {
            width: 8.33333%;
            float:left;
            padding-top:30px;
            padding-right: 18px;
            padding:30px 25px 4px;
          }
        li{
          a{
            border-bottom:4px solid transparent;

            &.active{
             border-bottom:4px solid color(cyan-lightest);
           }
          }
           
           .veracity-icon{
              cursor:pointer;
              
              color: color(dark-blue);
            }
           a.veracity-icon-tip{
             position: relative;
             &:after{
                    content: attr(data-counter);
                    position: absolute;
                    text-align: center;
                    background-color: rgb(0, 159, 218);
                    color: rgb(255, 255, 255);
                    right: -4px;
                    top: -16px;
                    font-size: 12px;
                    line-height: 1;
                    border-radius: 100%;
                    padding: 1px 4px;
                    font-family: Arial;
             }
           }
           
        }
      }

      .veracity-floating-menu{
           position:relative;
           padding-bottom:15px;

           &>span{
                 position: absolute;
                  bottom:-13px;
                  left:49%;
                  color:color(dark-blue);
                  padding:1px 7px;
                  transform:rotate(-90deg);
                  background-color:color(neutral-05);
                  border-radius: 50%;
                  font-weight: bold;
                  display: none;
              }

           &>ul.open{
             display: block;
             left:-280px;
             margin-left:auto;
           }

          .veracity-floating-menu-frame{
                  box-sizing: border-box;
                  position: absolute;
                  right: 0px;
                  width: 450px;
                  background: rgb(255, 255, 255);
                  list-style: none;
                  top:50px;
                  z-index:99;
              &>li{
                    margin: 0px;
                    padding: 25px 0px 15px;
                    border-bottom: 1px solid rgb(204, 204, 204);
                &:first-child{
                    padding-top: 0px;
                    border-bottom-color: rgb(153, 153, 153);
                }
                &:last-child{
                    padding-bottom: 0px;
                    border-bottom: none;
                }

                h4{
                      font-family: "Avenir Next", Verdana, Geneva, sans-serif;
                      font-size: 14px;
                      color: rgb(204, 204, 204);
                      text-transform: uppercase;
                      margin: 0px;
                      padding: 0px 0px 10px;
                      &:first-child{
                        color: rgb(153, 153, 153);
                      }
                }

              .veracity-floating-menu-link-section{
                          list-style: none;
                          margin: 0px;
                          padding: 0px;
                          li{
                              margin: 5px -16px;
                              text-indent: 8px;
                              padding: 8px 0;
                            &:hover{
                              background-color:color(sky-blue-lightest);
                              cursor: pointer;
                            }
                            a{
                                  text-decoration: none;
                                  display: inline-block;
                                  width:100%;
                            }
                            &:first-child{
                              margin-top: 0px;
                            }
                            &:last-child{
                              margin-bottom: 0px;
                            }
                          }
                }

                .veracity-floating-menu-user-section{
                    span{
                      display: block;
                      &:first-child{
                        font-weight: 700;
                      }
                    }
                 }
              }
          }
      }
  }
 

  .nav-container{
       
      .navbar-nav{

         &>li{
            position:relative;
            &:after{
              content:'';
              display: table;
              clear:both;
              font-size: 0;
            }

            .nav-mark{
              
              cursor:pointer;
              font-weight: bold;
              line-height:1;
              font-size:28px;
              display: none;
              user-select: none;
             }

            &>ul{
              list-style: none;
              position: absolute;
              top: 40px;
              left: 0px;
              width: 200px;
              padding:0;
              border: 1px solid rgba(0,0,0,.15);
              border-radius: 4px;
              box-shadow: 0 6px 12px rgba(0,0,0,.175);
              li{
                padding:10px 0;
                text-indent:10px;
                &:first-child{
                  padding-top:5px;
                }
                &:last-child{
                  padding-bottom:5px;
                }
                &:hover{
                 background-color:color(sky-blue-lightest);
                }
                a{
                  color: color(dark-blue);
                  text-decoration:none;
                }
              }
            }
         }
         
      }
  } 
}

$screen-sm-max:  767px;
@include small-down{
  .navbar-veracity{
     .veracity-space-stack{
        margin-bottom:0px;
     }

     .veracity-auth-user-menu{
      min-height: 50px;
      position: relative;

      &>ul{
        margin-bottom:0px;
        position: absolute;
        left: 50%;
        margin-left: -140px;
        .col-sm-1{
           padding-top:20px;
        }
      }
      .veracity-floating-menu{
            &>ul.open{
              position: static;
              margin:0 auto;
              float:none;
              margin-top:53px;
              display:block;
            }
            &>.open{
              display: block;
            }
      }
    }
    .right-icons{
      float:none;
      margin-top:60px;
      margin-bottom:10px;
      background-color:color(neutral-05);
    }


    .nav-container{
       padding-left:0;
       padding-right:0;
       .navbar-nav{
          &>li{   
            a{
              display: inline-block;
            }
            .nav-mark{
               float:right;
               display: block;
               user-select: none;
               height: 0;
            }
 
            &.collapsed{
              .nav-mark{
                .minus{
                  display:none;
                  transform:scale(2);
                  font-weight: 100;
                  float:left;
                  line-height: 0.7;
                }
                .plus{
                  display: inline;
                }
              }
            }
            &.active:not(.collapsed){
              .nav-mark{
                .minus{
                  display:inline-block;
                  transform:scale(2);
                  font-weight: 100;
                  float:left;
                  line-height: 0.7;
                }
                .plus{
                  display: none;
                }
              }
            }
            
            
             
            padding:10px 50px;
            border-bottom:1px solid color(neutral-05);
            font-size:16px;
             &:first-child{
              margin-top:-20px;
            }

            &>ul{
              list-style: none;
              position: static;
              width: 100%;
              padding:0;
              border:none;
              border-radius: 4px;
              box-shadow:none;
              li{
                padding:10px 0;
                text-indent:10px;
                &:first-child{
                  padding-top:5px;
                }
                &:last-child{
                  padding-bottom:5px;
                }
                &:hover{
                 background-color:color(sky-blue-lightest);
                }
                a{
                  color: color(dark-blue);
                  text-decoration:none;
                  word-break: break-all;
                  font-size: 14px;
                }
              }
            }


          }
       }
    }
  }
   
}



$screen-sm-max:  470px;
@include small-down{
  .navbar-veracity{

     .veracity-auth-user-menu{

      .veracity-floating-menu{
            &>ul.open{

              width:300px;
            }

      }
    }
   

  }
   
}

.i-alert{
  @include svg-commong('i-alert',false,true);
}
.i-profile{
  @include svg-commong('i-profile',false,true);
}
.i-services{
  @include svg-commong('i-services',false,true);
}
.i-support{
  @include svg-commong('i-support',false,true);
}
