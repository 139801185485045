
// SETTINGS
@import '../settings/text-variables';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BLOCKS
 * -----------------------------------------------------------------------------
 *
 */

/**
 *
 *
 * -----------------------------------------------------------------------------
 *  LISTS
 * -----------------------------------------------------------------------------
 *  List styles and modifiers for ordered, unordered and definition lists.
 *
 */

// Ordered and unordered lists
.ul,
.ol {
  margin: .25rem 0 .75rem;

  li {
    line-height: 1.25rem;
    margin-bottom: .25rem;

    // Nested lists
    ul,
    ol {
      margin-top: .25rem;
    }
  }
}

// Unnumbered list
.ul {
  list-style-type: square;
}

// Definition lists
dl {
  dt {
    font-weight: 700;
  }
  dd {
    line-height: $dnvgl-line-height;
    margin: 0 0 (.5 * $dnvgl-line-height);
  }
}

// Margin fix for last-child
ul,
ol,
li,
dl,
dd {
  &:last-child {
    margin-bottom: 0;
  }
}


/**
 * LIST MODIFIERS
 * --------------
 */

.ul.ul-spacious,
.ol.ol-spacious {
  margin: 1rem 0;

  li {
    line-height: $dnvgl-line-height-condensed;
    margin-bottom: .5rem;

    // Nested lists
    ul,
    ol {
      margin-top: .5rem;
    }
  }
}


// TODO: Add to list demo page!
// Unindented lists
.ul.ul-unindented,
.ol.ol-unindented {
  padding-left: 20px;  // Aligns with bullets
}


/**
 * STYLED LISTS
 * ------------
 */

// Common configuration for the styled lists
.ul.ul-checkmarks,
.ol.ol-numbered {
  li {
    line-height: $dnvgl-line-height;
    margin-bottom: 1rem;

    &::before {
      color: color(sea-blue);
      display: block;
      float: left;
      font-size: 1.875rem;
      line-height: 1.875rem;
      width: 2.5rem;
    }
  }
}

// Checkmark lists
.ul.ul-checkmarks {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 3.25rem;

  li::before {
    // TODO: Update to use the SVG icon instead.
    content: '\E641';
    font-family: dnvgl-icons, sans-serif;
    margin: -.125rem 0 0 -3.25rem;
  }
}

// Custom numbered list
.ol.ol-numbered {
  counter-reset: ol-counter;
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 3.25rem;

  li::before {
    content: counter(ol-counter, decimal-leading-zero);
    counter-increment: ol-counter;
    margin: .1rem 0 0 -3.25rem;
  }
}


/**
 * LISTS BULLET HEADLINE
 * ---------------------
 */

.li-headline {
  display: block;
  font-weight: 700;
}
