
// -----------------------------------------------------------------------------
//  SPACING MIXINS
// -----------------------------------------------------------------------------
//  Driven by the variables defined in 'spacing-variables.scss', these mixins
//  are used to specify the spacing most components in the style guide.
//  Consult the Style guide for an overview of the spacing system:
//  http://dnvglstyleguide.azurewebsites.net/?p=layout-spacing-system
//
//  DEPENDENCIES
//  Relies on 'rwd-mixins.scss' to specify breakpoints.


// SPACING MIXIN
// -------------
// Sets padding, margin and border. Subtracts the border width from the padding,
// so as to keep the content aligned with content in surrounding blocks.

@mixin spacing($type, $border-type: null, $important: false) {

  // Handle important modifier
  $important-flag: null;
  @if $important == true {
    $important-flag: '!important';
  }

  // PADDING
  @if map-has-key($inset-paddings, $type) {

    // Variables
    $border-width: 0;  // Default is no border

    // Configure border first, if specified
    @if $border-type {
      // Sanity check
      @if not(map-has-key($block-borders, $border-type)) {
        @error 'ERROR: Specified border type does not exist in map $block-borders';
      }

      // Look up border properties
      $border: map-get($block-borders, $border-type);
      $border-width: map-get($border, width);
      $border-color: map-get($border, color);

      // Set border
      border: $border-width solid $border-color;
    }

    // Look up padding properties
    // $paddings will be a list with several values, for example '4px 8px'
    $paddings: map-get($inset-paddings, $type);
    // Calculate padding presets minus border width to get actual padding
    $actual-paddings: null;
    @each $padding in $paddings {
      $actual-paddings: append($actual-paddings, $padding - $border-width);
    }

    // Set padding
    padding: $actual-paddings #{$important-flag};
  }

  // MARGIN
  @else if map-has-key($stack-margins, $type) {
    $margins: map-get($stack-margins, $type);
    @if length($margins) == 1 {
      // STACK - Bottom margin
      margin-bottom: nth($margins, 1) #{$important-flag};
      // TODO: Maybe duplicating the blocks a bit?
      // Removes the bottom margin of the last child item. This ensures
      // that the block itself is in control of it's bottom padding.
      & > :last-child {
        margin-bottom: 0;
      }
    } @else if length($margins) == 2 {
      // INLINE - Right and bottom margin
      float: left;
      margin: 0 nth($margins, 2) nth($margins, 1) 0 #{$important-flag};
    } @else {
      @error 'ERROR: Spacing definition for type "#{$type}" has more than two properties.';
    }
  } @else {
    @error 'ERROR: Spacing type "#{$type}" does not exist in either $inset-paddings map or $stack-margins map.';
  }

}


// RESPONSIVE SPACING MIXIN
// ------------------------
// Responsive inset spacing shrinks one size on mobiles ($screen-xs-max breakpoint).

@mixin spacing-responsive($type, $border-type: null) {
  @if map-has-key($responsive-insets, $type) {
    $mobile-type: map-get($responsive-insets, $type);
    // Now knows the inset type to use on larger screens ($type)
    // and on smaller, mobile screens ($mobile-type).
    @include spacing($type, $border-type);
    @include xsmall {
      @include spacing($mobile-type, $border-type);
    }
  } @else {
    @error 'ERROR: There is no dynamic spacing defined for "#{$type}".';
  }
}


// CONTAIN CHILDREN MIXIN
// ----------------------
// Contains the margins of first and last child element

@mixin contain-children {
  // Removes the top margin of the first child item. This ensures
  // that the block itself is in control of it's top padding.
  & > :first-child,
  & > :first-child > :first-child {
    margin-top: 0;
  }

  // Removes the bottom margin of the last child item. This ensures
  // that the block itself is in control of it's bottom padding.
  & > :last-child
  & > :last-child > :last-child {
    margin-bottom: 0;
  }
}
