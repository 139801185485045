

// -----------------------------------------------------------------------------
//  SPACING VARIABLES
// -----------------------------------------------------------------------------
//  Drives the system used for spacing out of all DNV GL components.
//  Consult the Style guide for an overview of the spacing system:
//  http://dnvglstyleguide.azurewebsites.net/?p=layout-spacing-system


// SPACING PROGRESSION
// -------------------
// The progressions are loosely based on multiples of 2px, with the font size
// of 16 pixels being the natural default. This also aligns to Bootstrap's
// $padding-large-horizontal value. See [1] below.

$space-xxs:       2px;  // XXS
// $space-xxs-xs:    3px;  // TODO: Remove
$space-xs:        4px;  // XS
$space-xs-sm:     6px;
$space-sm:        8px;  // SM
$space-sm-md:    12px;
$space-md:       16px;  // MD  [1]
$space-md-lg1:   20px;
$space-md-lg2:   24px;
$space-lg:       30px;  // LG
$space-lg-xl1:   40px;
$space-lg-xl2:   44px;  // Exception
$space-xl:       56px;  // XL
$space-xl-xxl:   72px;  // Exception
$space-xxl:      80px;  // XXL


// BLOCK BORDERS
// -------------
// Defines the available border styles in our spacing and block system

$hairline-border-width: 1px;
$broad-border-width:  4px;

$block-borders: (
  bordered: ( width: $hairline-border-width, color: color(neutral-20) ),
  framed:   ( width: $broad-border-width,    color: color(neutral-20) )
);


// INSET PADDING
// -------------
// Defines the 'padding' attribute for the different inset types.

$inset-paddings: (

  // INSET (Default=MD)
  // Slightly more padding at the bottom
  inset-xs: ( $space-sm     $space-sm     $space-sm-md  ),
  inset-sm: ( $space-md     $space-md     $space-md-lg2 ),
  inset-md: ( $space-lg     $space-lg     $space-lg-xl1 ),
  inset-lg: ( $space-lg-xl2 $space-lg-xl2 $space-xl     ),
  inset-xl: ( $space-xl     $space-xl     $space-xl-xxl ),
  // Default = MD
  inset:    ( $space-lg     $space-lg     $space-lg-xl1 ),

  // DECK
  deck-xs:  ( $space-sm     0 $space-sm-md  ),
  deck-sm:  ( $space-md     0 $space-md-lg2 ),
  deck-md:  ( $space-lg     0 $space-lg-xl1 ),
  deck-lg:  ( $space-lg-xl1 0 $space-xl     ),
  deck-xl:  ( $space-xl     0 $space-xl-xxl ),
  // Default = MD
  deck:     ( $space-lg     0 $space-lg-xl1 ),

  // SQUARE
  // Equal padding on all sides
  square-xxs: ( $space-xxs ),
  square-xs:  ( $space-xs  ),
  square-sm:  ( $space-sm  ),
  square-md:  ( $space-md  ),
  square-lg:  ( $space-lg  ),
  square-xl:  ( $space-xl  ),
  // Default = MD
  square:     ( $space-md  ),

  // SQUISH
  // Inset with 50% reduced padding top and bottom
  squish-xs: ( $space-xxs   $space-xs     ),
  squish-sm: ( $space-xs    $space-sm     ),
  squish-md: ( $space-xs-sm $space-sm-md  ),
  squish-lg: ( $space-sm    $space-md-lg1 ),
  // Default = MD
  squish:    ( $space-xs-sm $space-sm-md  )

);


// RESPONSIVE INSET PADDING
// ---------------------
// Defines insets that will shrink on mobile, to give less padding.
// For each inset spacing (key), the spacing for mobile is specified (value).

$responsive-insets: (

  inset:     'inset-sm',  // Default = MD
  inset-md:  'inset-sm',
  inset-lg:  'inset-md',
  inset-xl:  'inset-lg',

  deck:      'deck-sm',  // Default = MD
  deck-md:   'deck-sm',
  deck-lg:   'deck-md',
  deck-xl:   'deck-lg',

  square:    'square-sm',  // Default = MD
  square-md: 'square-sm',
  square-lg: 'square-md',
  square-xl: 'square-lg'

);


// STACK AND INLINE MARGINS
// ------------------------
// Defines the 'margin' attribute for the different stacking types.

$stack-margins: (

  // NOTE: Because of our spacing system, which relies on using
  // margins only below and to the right of elements, margins are
  // defined as lists with only TWO properties: ( bottom right ).

  // STACK
  // Bottom padding only.
  stack-xxs: ( $space-xs  ),
  stack-xs:  ( $space-sm  ),
  stack-sm:  ( $space-md  ),
  stack-md:  ( $space-lg  ),
  stack-lg:  ( $space-xl  ),
  stack-xl:  ( $space-xxl ),
  // Special stack size for 'line-height' stacking  // TODO: Document!
  stack-line-height:  ( 1.5rem ),
  // Default = MD
  stack:     ( $space-lg  ),

  // INLINE
  // Bottom and right padding.
  inline-sm: ( $space-xs    $space-xs    ),
  inline-md: ( $space-sm    $space-sm    ),
  inline-lg: ( $space-sm-md $space-sm-md ),
  // Default = MD
  inline:    ( $space-sm    $space-sm    ),

  // TODO: Remove these??
  inline-sm-wide: ( $space-xs    $space-sm-md  ),
  inline-md-wide: ( $space-sm    $space-md-lg2 ),
  inline-lg-wide: ( $space-sm-md $space-lg     ),
  // Default = MD
  inline-wide:    ( $space-sm    $space-md-lg2 )

);
