.rule-saves {
  //pagination
  .pagination {
    display: inline-block;
    margin: 0 0 2px !important;
    border-radius: 0px !important;
  }

  .pagination>li>a:hover, .pagination>li>span:hover {
    z-index: 2;
    color: #000 !important;    
	background-color: #ccecf8 !important;
    cursor: pointer;
  }

  .pagination>li>a:focus, .pagination>li>span:focus {
      z-index: 2;
      color: #000 !important;    
    background-color: #99d9f0 !important;
      cursor: pointer;
      border-color: #ddd;
  }

  .pagination>li:first-child>a, .pagination>li:first-child>span {
      margin-left: 0;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
  }

  .pagination>li:last-child>a, .pagination>li:last-child>span {
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
  }

  .pagination > li > a, .pagination > li > span {
    position: relative;
    float: left;
    padding: 3px 8px !important;
    line-height: 1.2 !important;
    text-decoration: none;
    color: #003591 !important;
    border: 1px solid #ccc;
    background-color: #fff !important;
    margin-right: 4px;
    font-weight: 600;
  }

  .pager li, .pagination > li {
    display: inline;
    font-size: 11px;
  }

  .input-search {
    float: left;
    width: 100% !important;
    margin-bottom: 10px;
    border: 1px solid #009fda !important;
  }

  .savedProject {
    width: 100%;
    margin-top: -10px;

  }

  .savedProjects_head {
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    overflow: hidden;
    height: auto;
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .col-savedProject-responsive > .savedProjects_head {
    border-bottom: none;
  }

  .savedProjects_title {
    width: 100%;
    padding: 3px 0px 4px 0px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: bold;    
    line-height: 1.2;
  }

  .savedProjects_date {
    color: #999;
    font-size: 14px;
    margin-bottom: 3px;
    
    &.updated {
      margin-bottom: 0px;
    }
  }

  .savedProjects_item {
    width: 100%;
    background-color: #f2f2f2;
    height: auto;
    padding: 3px 10px 5px 10px;
    color: #003591;
    margin-bottom: 7px;
    
    &.active,
    &.active:hover, {
      background-color: #e5f5fb;
    }

    &.active > .savedProjects_title {
      color:#000;
    }
  }

  .savedProjects_search {
    margin: 10px 0;
  }

  .col-savedProject-responsive > .savedProjects_item {
    width: 47.3%;
    float: left;
    margin: 0px 10px 10px 0;
    background-color: #f2f2f2;
    padding: 0 10px 5px;
  }


    .savedProjects_item:hover {
    background-color: #ccecf8;
    cursor: pointer;
  }

  .savedProjects_item > p > a {
    color: #003591;
  }

  .savedProjects_item > p > a:hover {
    color: #000;
    text-decoration: none;
  }

  .col-savedProject-responsive {
    display: none;
    width: 100%;
    float: left;
    margin: 0px 12px 0px 0;
    height: auto;
    padding-left: 10px;
    padding-bottom: 5px;
    z-index: 20;
  }

  .col-content-responsive {
    display: none;
  }

}