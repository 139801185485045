
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';
@import '../settings/text-variables';

// MIXINS
@import '../mixins/clearfix';
@import '../mixins/rwd-mixins';
@import '../mixins/spacing-mixins';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  SPACING HELPERS
 * -----------------------------------------------------------------------------
 *  This is the system used for spacing out of all DNV GL components.
 *  Consult the Style guide for a systems overview:
 *  http://localhost:3000/?p=layout-spacing-system
 *
 */


/*
 *  INSET HELPER CLASSES
 *  --------------------
 */

@each $type, $paddings in $inset-paddings {

  // Generate default helper class, e.g. '.space-inset-lg'
  .space-#{$type} {
    @include spacing($type, null, true);
  };

  // Find the smallest padding property
  $min-padding: min($paddings...);

  // Generate helper class for bordered version if the padding is wide enough
  @if $min-padding > $hairline-border-width {
    .space-#{$type}-bordered {
      @include spacing($type, bordered, true);
    };
  }

  // Generate helper class for framed version if the padding is wide enough
  @if $min-padding > $broad-border-width {
    .space-#{$type}-framed {
      @include spacing($type, framed, true);
    };
  }

}


/*
 *  STACK AND INLINE HELPER CLASSES
 *  -------------------------------
 */

@each $type, $margins in $stack-margins {

  /* #{$type} */

  // Generate default helper class
  .space-#{$type} {
    @include spacing($type, null, true);
  };

  // TODO: Will get an '.inline-children-' class, which is wrongly named.
  // This should be simply '.inline-children'.

  // Generate convenience classes for inlining, e.g. .inline-children-sm
  @if str-index($type, 'inline') {
    // Uses str-slice() to pick out size ("lg" or "wide-md")
    .inline-children-#{str-slice($type, 8, 30)} {
      @include clearfix;
      // Adds negative bottom margin to compensate for child
      // element margins in the last row of contained elements
      margin-bottom: - nth($margins, 1) !important;
      // overflow: hidden; // TODO: Remove?
      & > * {
        // Applies the 'Inline spacing' margins to all child elemewnts.
        @include spacing($type, null, true);
      }
    }
  }

}


/*
 *  INSET DECK MODIFIER
 *  -------------------
 */

// Doubles the specificity of the class to avoid !important
.space-inset-deck.space-inset-deck {
  margin-left: 0;
  margin-right: 0;
}


/*
 *  RESPONSIVE INLINE HELPER CLASSES
 *  --------------------------------
 */

.space-inset-responsive,
.space-inset-md-responsive  { @include spacing-responsive(inset-md); }
.space-inset-lg-responsive  { @include spacing-responsive(inset-lg); }
.space-inset-xl-responsive  { @include spacing-responsive(inset-xl); }

.space-deck-responsive,
.space-deck-md-responsive   { @include spacing-responsive(deck-md);   }
.space-deck-lg-responsive   { @include spacing-responsive(deck-lg);   }
.space-deck-xl-responsive   { @include spacing-responsive(deck-xl);   }

.space-square-responsive,
.space-square-md-responsive { @include spacing-responsive(square-md); }
.space-square-lg-responsive { @include spacing-responsive(square-lg); }
.space-square-xl-responsive { @include spacing-responsive(square-xl); }


/*
 *  GENERAL SPACING HELPER CLASSES
 *  ------------------------------
 */

.alpha,  // Legacy name
.first-child {
  margin-top: 0 !important;
}

.omega,  // Legacy name
.last-child {
  margin-bottom: 0 !important;
}

.contain-children {
  @include contain-children;
}


.space-top-xxs { margin-top: $space-xxs !important; }
.space-top-xs  { margin-top: $space-xs  !important; }
.space-top-sm  { margin-top: $space-sm  !important; }
.space-top-md  { margin-top: $space-md  !important; }
.space-top-lg  { margin-top: $space-lg  !important; }
.space-top-xl  { margin-top: $space-xl  !important; }

.space-right-xxs { margin-right: $space-xxs !important; }
.space-right-xs  { margin-right: $space-xs  !important; }
.space-right-sm  { margin-right: $space-sm  !important; }
.space-right-md  { margin-right: $space-md  !important; }
.space-right-lg  { margin-right: $space-lg  !important; }
.space-right-xl  { margin-right: $space-xl  !important; }

.space-bottom-xxs { margin-bottom: $space-xxs !important; }
.space-bottom-xs  { margin-bottom: $space-xs  !important; }
.space-bottom-sm  { margin-bottom: $space-sm  !important; }
.space-bottom-md  { margin-bottom: $space-md  !important; }
.space-bottom-lg  { margin-bottom: $space-lg  !important; }
.space-bottom-xl  { margin-bottom: $space-xl  !important; }

.space-left-xxs { margin-left: $space-xxs !important; }
.space-left-xs  { margin-left: $space-xs  !important; }
.space-left-sm  { margin-left: $space-sm  !important; }
.space-left-md  { margin-left: $space-md  !important; }
.space-left-lg  { margin-left: $space-lg  !important; }
.space-left-xl  { margin-left: $space-xl  !important; }
