
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';
@import '../settings/text-variables';

// MIXINS
@import '../mixins/clearfix';
@import '../mixins/rwd-mixins';
@import '../mixins/spacing-mixins';

/**
 *
 *
 * -----------------------------------------------------------------------------
 *  TEXT COLOR HELPER CLASSES
 * -----------------------------------------------------------------------------
 *  Helper classes for all allowed text colors.
 *
 *  Not all colors are allowed to use as text-colors, but we provide them all
 *  as helper classes for easier maintainability of the color map. Refer to
 *  the style guide for allowed color combinations.
 *
 *  NOTE: Do not over-use. Consider if color should be part of a class that
 *  defines and styles the block in question rather than using helper classes.
 *
 */

@each $name, $color in $dnvgl-colors {
  .text-#{$name} {
    color: $color !important;
  }
}

// Shorthand classes
.text-white {
  color: color(primary-white) !important;
}
.text-black {
  color: color(primary-black) !important;
}
