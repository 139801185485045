.app-nav {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
}

.no-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.navbar-container {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    background-color: #e5e5e5;
}

.navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 14px;
}

.navbar-nav > li:first-child  {
    margin-left: 15px;
}


.navbar-header {
    color: #222;
    margin: 6px;
}

li.user-name {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 14px;
}