footer {
	padding: 30px 0;
	background-color: #fff;
	border-top: 1px solid #ccc;
  margin-top: 70px;
  
  .footer_content {
    font-size: 14px;
    color: #777;
  }
}

