
// RELATIVE PATH
$relative-path: '../../' !default;

// SETTINGS
@import '../settings/spacing-variables';


/*
 *
 *
 * -----------------------------------------------------------------------------
 *  ICON FONT
 * -----------------------------------------------------------------------------
 *
 *  The DNV GL icon font is part og the overall DNV GL branding.
 *
 *  NOTE: The 'Glyphicon Halflings' icons are available through Bootstrap,
 *  which we build upon. You can see the Glyphicons in action here:
 *  http://getbootstrap.com/components/#glyphicons
 *
 *  The following font formats are offered:
 *
 *  WOFF : The preferred format
 *  EOT  : For IE8 and lower
 *  SVG  : For iOS4 and lower
 *  TTF  : For Android
 *
 */

// -----------------
//  ICON FONT MIXIN
// -----------------

@mixin icon-font {
  // Better font rendering
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: 'dnvgl-icons', sans-serif;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  margin-right: .3em;  // TODO: Consider! Remove?
  speak: none;
  text-transform: none;
}


// My DNV GL icon font
@font-face {
  font-family: 'dnvgl-icons';
  font-style: normal;
  font-weight: normal;
  src: url($relative-path + 'fonts/mydnvgl-iconfont-12.2/mydnvgl-icons.eot');
  src: url($relative-path + 'fonts/mydnvgl-iconfont-12.2/mydnvgl-icons.eot') format('embedded-opentype'),
       url($relative-path + 'fonts/mydnvgl-iconfont-12.2/mydnvgl-icons.woff') format('woff'),
       url($relative-path + 'fonts/mydnvgl-iconfont-12.2/mydnvgl-icons.ttf') format('truetype'),
       url($relative-path + 'fonts/mydnvgl-iconfont-12.2/mydnvgl-icons.svg') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  display: inline-block;  // To avoid underline on icons in links
  line-height: 1;
  text-decoration: none !important;
  vertical-align: middle;
}

// Icon font rendering
[class^='icon-']::before,
[class*=' icon-']::before {
  @include icon-font;
}


/*
 *
 *  ICON SIZE UTILITIES
 *  -------------------
 */

.icon-size-lg {
  font-size: 1.25em;
}

.icon-size-xl {
  font-size: 1.75em;
}

.icon-size-xxl {
  font-size: 2.5em;
}


/*
 *
 *  ICON MARGIN UTILITIES
 *  ---------------------
 */

// Removes the default icon margin on the right side of the icon
.icon-no-margin::before {  // TODO: Consider? Remove?
  margin: 0;
}


/*
 *
 *  ICON CONVENIENCE CLASSES
 *  ------------------------
 *  Adds some spacing before/after the icon and removes underlining.
 *  Use for icons in links.
 */

.lead-icon {
  margin-right: $space-xs-sm;
}

.tail-icon {
  margin-left: $space-xs-sm;
}

// TODO: Remove?
// .lead-icon,
// .lead-icon:hover,
// .lead-icon:focus,
// .lead-icon:active,
// .tail-icon,
// .tail-icon:hover,
// .tail-icon:focus,
// .tail-icon:active,
*:hover  .lead-icon,
*:focus  .lead-icon,
*:active .lead-icon,
*:hover  .tail-icon,
*:focus  .tail-icon,
*:active .tail-icon {
  text-decoration: none !important;
}


/*
 *
 *  ICON CLASSES
 *  -------------------
 *  Some icons have legacy class names because they have been renamed
 *  between font versions. They might still be in use under the old names.
 */



/* 1. PERSON ICONS */

.icon-user::before {
  content: '\e615';
}

.icon-usergroup::before {
  content: '\e67e';
}

.icon-adminuser::before {
  content: '\e900';
}



/* 2. ARROWS AND BRACKETS */

.icon-arrow-right::before {
  content: '\e60b';
}

.icon-arrow-left::before {
  content: '\e623';
}

.icon-arrow-navigate-back::before {
  content: '\e624';
}

.icon-left::before {
  content: '\e63d';
}

.icon-right::before {
  content: '\e63e';
}

.icon-triangle-right::before {
  content: '\e608';
}

.icon-triangle-down::before {
  content: '\e62f';
}



/* 3. CONTACT AND COMMUNICATION */

.icon-contact-us::before {
 content: '\e643';
}

.icon-contact-woman::before {
 content: '\e61f';
}

.icon-contact-man::before {
  content: '\e620';
}

.icon-support::before {
 content: '\e610';
}

.icon-speech-bubble::before {
  content: '\e60d';
}

.icon-speech-bubbles::before {
  content: '\e64d';
}

.icon-phone::before {
  content: '\e646';
}

.icon-email::before {
  content: '\e647';
}

.icon-mobile::before {
 content: '\e67f';
}



/* 4. USER INTERFACE ICONS */

.icon-key::before {
  content: '\e901';
}



/* 5. GEOGRAPHY AND LOCATION */

.icon-world::before {
  content: '\e631';
}

.icon-globe::before {
  content: '\e676';
}

.icon-worldmap::before {
  content: '\e644';
}

.icon-gps::before {
 content: '\e640';
}



/* 6. SOCIAL MEDIA ICONS */

.icon-twitter::before {
 content: '\e64b';
}

.icon-facebook::before {
 content: '\e64a';
}

.icon-linkedin::before {
 content: '\e649';
}

.icon-share::before {
  content: '\e62e';
}




.icon-calendar::before {
 content: '\e607';
}

.icon-update-software::before {
 content: '\e612';
}

.icon-lock-open::before {
  content: '\e61b';
}

.icon-menu2::before {
  content: '\e61c';
}

.icon-files::before {
  content: '\e627';
}

.icon-menu::before {
  content: '\e630';
}

.icon-checkbox-unchecked::before {
  content: '\e637';
}

.icon-checkbox-checked::before {
  content: '\e638';
}

.icon-radiobutton-unchecked::before {
  content: '\e63b';
}

.icon-radiobutton-checked::before {
  content: '\e639';
}

.icon-favourite-add::before {
  content: '\e63c';
}

.icon-service-finder::before {
 content: '\e600';
}

.icon-compliance2::before {
 content: '\e601';
}

.icon-plus::before {
 content: '\e605';
}

.icon-quote::before {
  content: '\e609';
}

.icon-grid::before {
 content: '\e60f';
}

.icon-courses::before {
  content: '\e611';
}

.icon-play::before {
  content: '\e613';
}

.icon-lock::before {
  content: '\e614';
}

.icon-compass::before {
 content: '\e616';
}

.icon-target::before {
  content: '\e618';
}

.icon-laboratory::before {
  content: '\e619';
}

.icon-documents::before {
  content: '\e62b';
}

.icon-branch::before {
  content: '\e634';
}

.icon-polarbear::before {
  content: '\e635';
}

.icon-cycle::before {
  content: '\e636';
}

.icon-coffee::before {
  content: '\e645';
}

.icon-expand::before {
  content: '\e64e';
}

.icon-navigation-down::before {
  content: '\e64f';
}

.icon-navigation-top::before {
  content: '\e650';
}

.icon-article::before {
 content: '\e652';
}

.icon-press-release::before {
  content: '\e653';
}

.icon-pdf::before {
  content: '\e654';
}

.icon-diploma::before {
  content: '\e656';
}

.icon-food-beverage::before {
  content: '\e660';
}

.icon-oilrig-structure::before {
  content: '\e662';
}

.icon-exploding-danger-hazard::before {
  content: '\e669';
}

.icon-water-management::before {
  content: '\e670';
}

.icon-innovation-research::before {
  content: '\e671';
}

.icon-piechart::before {
  content: '\e673';
}

.icon-kpi-performance::before {
  content: '\e674';
}

.icon-video::before {
 content: '\e67d';
}

.icon-storage-tanks::before {
  content: '\e680';
}

.icon-customer-portal::before {
 content: '\e682';
}

.icon-alert::before {
  content: '\e642';
}

.icon-bell::before {
  content: '\e621';
}

.icon-binoculars::before {
 content: '\e66d';
}

.icon-biomass::before {
 content: '\e668';
}

.icon-briefcase::before {
 content: '\e626';
}

.icon-butterfly::before {
 content: '\e66e';
}

.icon-car::before {
 content: '\e658';
}

.icon-chart::before {
 content: '\e672';
}

.icon-checkmark::before {
  content: '\e641';
}

.icon-cocktail::before {
 content: '\e661';
}

.icon-compliance::before {
  content: '\e655';
}

.icon-default::before {
 content: '\e63f';
}

.icon-delete::before {
  content: '\e629';
}

.icon-dollar::before {
 content: '\e67a';
}

.icon-download::before {
 content: '\e60a';
}

.icon-factory::before {
 content: '\e66a';
}

.icon-favourite::before {
 content: '\e63a';
}

.icon-heart::before {
 content: '\e625';
}

.icon-heartbeat::before {
 content: '\e602';
}

.icon-helmet::before {
 content: '\e65c';
}

.icon-idea::before {
 content: '\e60e';
}

.icon-info::before {
  content: '\e617';
}

.icon-leaf::before {
 content: '\e603';
}

.icon-lifejacket::before {
 content: '\e65d';
}

.icon-link::before {
 content: '\e606';
}

.icon-list::before {
 content: '\e62c';
}

.icon-lunch::before {
 content: '\e65f';
}

.icon-meal::before {
 content: '\e65e';
}

.icon-minus::before {
 content: '\e604';
}

.icon-money::before {
 content: '\e679';
}

.icon-new::before {
 content: '\e62a';
}

.icon-newslist::before {
 content: '\e61e';
}

.icon-company::before {
  content: '\e67c';
}

.icon-oilrig::before {
 content: '\e663';
}

.icon-organisation::before {
 content: '\e675';
}

.icon-pictures::before {
 content: '\e628';
}

.icon-pin::before {
 content: '\e648';
}

.icon-pipelines::before {
 content: '\e66b';
}

.icon-plane::before {
 content: '\e65b';
}

.icon-powerlines::before {
 content: '\e664';
}

.icon-preview::before {
 content: '\e61d';
}

.icon-print::before {
 content: '\e62d';
}

.icon-publications::before {
 content: '\e678';
}

.icon-search::before {
 content: '\e60c';
}

.icon-service::before {
  content: '\e651';
}

.icon-settings::before {
  content: '\e622';
}

.icon-ship::before {
  content: '\e632';
}

.icon-smartgrid::before {
 content: '\e666';
}

.icon-solarpanels::before {
 content: '\e665';
}

.icon-stamp::before {
 content: '\e657';
}

.icon-subscribe::before {
 content: '\e67b';
}

.icon-tools::before {
 content: '\e677';
}

.icon-train::before {
 content: '\e659';
}

.icon-transformator::before {
 content: '\e667';
}

.icon-tree::before {
 content: '\e66f';
}

.icon-truck::before {
 content: '\e65a';
}

.icon-utility::before {
 content: '\e66c';
}

.icon-utility-electrical::before {
 content: '\e64c';
}

.icon-venue::before {
 content: '\e681';
}

.icon-view::before {
 content: '\e61a';
}

.icon-windmills::before {
 content: '\e633';
}

.icon-admin-user::before {
 content: '\e900';
}

.icon-key::before {
 content: '\e901';
}
