
// SETTINGS
@import '../settings/dnvgl-color-settings';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BLOCKS
 * -----------------------------------------------------------------------------
 *
 */

/**
 *
 *
 * -----------------------------------------------------------------------------
 *  LINKS
 * -----------------------------------------------------------------------------
 *  This type of comment denotes CSS sections. Typically a particular block
 *  with it's modifiers and elements (BEM) in a separate .scss file.
 *
 */

// Basic link definition
// Builds on Bootstrap's default link
a {
  color: color(digital-blue);
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &:focus {
    // Changes focus colour, but this is probably Chrome specific
    // outline: 5px auto color(sky-blue);
    // outline-offset: -2px;

    // TODO: Proper fix across browsers?
    outline: 0;
    outline-offset: 0;
  }

  &:hover {
    color: darken(color(sea-blue), 5%);
  }
}

// For links in running texts
// Turns on underlining
a.link-em {
  text-decoration: underline;

  &:active,
  &:focus,
  &:hover,
  &:link,
  &:visited {
    text-decoration: underline;
  }
}

// Convenience class, that turns on the 'link-em' style for all contained links
.link-em-container {
  a:active,
  a:focus,
  a:hover,
  a:link,
  a:visited {
    text-decoration: underline;
  }
}

a.link-invisible {
  color: inherit;
  text-decoration: inherit;
}
