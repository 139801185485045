.editor-panel {
  border-radius: 0;
  border: 1px solid #ddd;
  
#editionEditor {
  height: 500px;
}
  // .editor-holder {
  //   padding: 0;
   
  // }
}


