.rule-block {
  
  h3 {
    border-bottom: 1px solid #e5e5e5;
    padding: 3px 0px 12px 0px;
    font-weight: bold;
    margin: 0px 0px 20px;
  }

  button {
      span.circle {
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        //padding: 2px;
        padding: 4px 3px 4px 4px;
      }

      span.glyphicon-minus {
        padding: 4px 5px 4px 3px;
      }
    }

  label.control-label {
    margin-right: 20px;
  }
  
  .panel {
    border: none;
    border-radius: 0;
    box-shadow: none;
  
    
  }

  .panel-bordered {
    border: 5px solid #ccc;
  }

  .panel-body {
  
  }

  .panel-default > .panel-heading {
   border-bottom: none  
  }


  .panel-heading {
     h4 {
       margin-top: 6px;
       margin-bottom: 6px;
       padding-top: 0;
       padding-bottom: 0;
     }
  }

  .block-save {
    border-top: 1px solid #ccc;
    height: 70px;
    margin-top: 20px;
    padding-top: 8px;
  }
      
  .itembox {
    width: 45%;
    margin-right: 4.5%;
    display: inline-flex;
    position: relative;
    vertical-align: bottom;
  
    .form-group {
      width: 100%;
      margin-bottom: 1.4rem;
    }
    
    .form-control {
      border: 1px solid #009fda;
      font-size: 16px;
      padding: 6px 10px;
    }

    .has-error .form-control {
      border: 1px solid #c4262e !important;
    }

    .has-error .control-label {
      color: #c4262e !important;
    }


    label {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 7px;
    }
    
    .katext-placeholder {
      padding-left: 0;
      > span {
        position: absolute;
        bottom: 25px;
        right: 8px;
      }
    }

    .val-placeholder {
      padding-left: 0;
      padding-right: 0;
    }

    .infoLink {
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: solid #ccc 1px;
      position: absolute;
      font-family: 'avenirdemi', sans-serif;
      font-size: 14px;
      padding: 0px 5px;
      color: #ccc;
    }

    .infoLink:hover {
      color: #009fda;
      border: solid #009fda 1px;
      cursor: pointer;
      background-color: #efefef;
    }

  }

  .itemboxfigure {
    .img-desc {
      width: 50%;
      margin-left: 25%;
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: 1200px){
    .itembox {
        width: 45% !important;
        margin-right: 4.5%;
    }
  }

  .btn-default-plain {
    border: none;
    color: #003591;
    background-color: transparent;

    &:active,
    &:focus,
    &:hover,
    &:active:focus {
    // Slightly different shadow than for input boxes (not inset)
      box-shadow: none; 
      border: none;  
      background-color: #ccecf8;
      color: #003591;
    }
  }

  .btn-md {
    padding: 6px 8px 8px 8px;
    font-size: 16px;
}

}