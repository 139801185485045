.rule-header {
  position: relative;

  .headline {
    margin-top: 10px;
    padding: 10px 0px 20px;
    line-height: 1.1;
    border-bottom: 1px solid #ccc;
    min-height: 40px;
    margin-bottom: 0;
    
    .rule-desc {
      font-weight: 200;
    }
    
    .rule-date {
      color: #ccc;
      font-size: 14px;
      font-weight: normal;
    }

    .out-dated-edition {
      color: #003591;
      display: inline-block;
      //border: 1px solid #003591;
      font-size: 16px;
      padding: 3px;
      font-weight: 500;
    }
    .project-info {
      margin-top: 13px;
      padding: 12px 12px 15px 16px;
      background-color: #e5f5fb;
      position: relative;

      .name {
        font-size: 24px;
        font-weight: bold;
      }
      .date {
        margin-left: 10px;
        color: #ccc;
        font-size: 14px;
        font-weight: normal;
      }
    
      .out-dated {
        color: #003591;
        display: inline-block;
        border: 1px solid #003591;
        font-size: 12px;
        padding: 2px;
        position: absolute;
        right: 6px;
        top: 6px;
        font-weight: 500;
        background-color: #eee;
      }
      
    }
  }
}