.editor-images {
  overflow-y: scroll;
  margin-top: 10px;

  ul.img-list {
    list-style-type: none;
    padding-left: 0;
    
    li {
      display: block;
      float: left;
      width: auto;
      margin: 10px 20px 10px 0;
      border: 1px solid #ccc;
      
      img {
        width: 200px;
        height: 140px;
        max-height: 104px;
      }

      .name {
        padding: 8px;
      }
      .actions {
        //border-top: 1px solid #ccc;

        a {
          font-size: 14px;
          font-weight: 600;
          text-decoration: underline;
          margin-right: 5px;
          padding-right: 4px;
          line-height: 30px;
          border-right: 1px solid #0067c5;
          &:last-child {
            border: none;
          }
        }
      }

    }
  
  }
}