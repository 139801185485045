
// SETTINGS
@import '../settings/text-variables';


/*
 *
 *
 * -----------------------------------------------------------------------------
 *  HEADINGS
 * -----------------------------------------------------------------------------
 *
 *
 * Definition of headings:
 * Tag: Should only cover basic/default styling. Easily overridden w/o !important.
 * Tag selector: Tag selectors, here .h*, should only cover visual changes, not including placement or spacing
 * Modifier: Modifiers should only cover placement and/or spacing.
 *
 * Example:
 * <h1 class="h4 page-title"></h1>
 *
 */

// Default heading settings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: inherit;
  font-weight: bold;
  // line-height: 1.5;   // TODO: PRI 1: Redo to cater to components!
  line-height: $dnvgl-line-height-condensed;
  margin: 1.5rem 0 .75rem;
  padding: 0;
}

// Headings standard settings
h1, .h1 { font-size: 1.2rem;  }
h2, .h2 { font-size: 1.8rem;   }
h3, .h3 { font-size: 1.6rem;  }
h4, .h4 { font-size: 1.3rem; }
h5, .h5 { font-size: 1.2rem;    margin: 1.25rem 0 .625rem; }
h6, .h6 { font-size: .875rem; margin: 1.25rem 0 .625rem; line-height: $dnvgl-line-height; text-transform: uppercase; }
// h1, .h1 { font-size: 1.75rem;  line-height: 2.25rem;   }
// h2, .h2 { font-size: 1.5rem;   line-height: 2.0625rem; }
// h3, .h3 { font-size: 1.25rem;  line-height: 1.875rem;  }
// h4, .h4 { font-size: 1.125rem; line-height: 1.5rem;    }
// h5, .h5 { font-size: 1rem;     line-height: 1.5rem;  margin: 1.25rem 0 .625rem; }
// h6, .h6 { font-size: .875rem;  line-height: 1.5rem;  margin: 1.25rem 0 .625rem; text-transform: uppercase; }

// Halve the spacing between consecutive headers
h1, .h1 { & + h2, & + .h2 { margin-top: .75rem; }}
h2, .h2 { & + h3, & + .h3 { margin-top: .75rem; }}
h3, .h3 { & + h4, & + .h4 { margin-top: .75rem; }}
h4, .h4 { & + h5, & + .h5 { margin-top: .75rem; }}
h5, .h5 { & + h6, & + .h6 { margin-top: .75rem; }}
