
// SETTINGS
@import '../settings/text-variables';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BASE CONFIGURATION
 * -----------------------------------------------------------------------------
 *  Overrides the standard Bootstrap buttons.
 *
 */


/*
 *  RESET BOX SIZING
 *  ----------------
 *  Heads up! This reset may cause conflicts with some third-party widgets.
 *  For recommendations on resolving such conflicts, see
 *  http://getbootstrap.com/getting-started/#third-box-sizing
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}


/*
 *  FONT SETTINGS
 *  -------------
 *  Overrides default font size in Bootstrap and specifies Brand font.
 */

html {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: $font-size-base;
  text-rendering: optimizeLegibility;
}

body {
  color: color(primary-black);
  font-family: 'Avenir Next', Avenir, Verdana, sans-serif;  // TODO: Turn into variable?
  font-size: $font-size-base;
}


/*
 *  iOS CLICKABLE FIX
 *  -----------------
 *  iOS "clickable elements" fix for role="button".
 *  Fixes "clickability" issue (and more generally, the firing of events such as
 *  focus as well) for traditionally non-focusable elements with role="button"
 *  see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
 */

[role='button'] {
  cursor: pointer;
}
