
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';

// MIXINS
@import '../mixins/rwd-mixins';
@import '../mixins/spacing-mixins';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  FOOTER
 * -----------------------------------------------------------------------------
 *
 */

body > footer,
.footer {
  @include spacing-responsive(deck-md);
  @include contain-children;
  background-color: color(neutral-10);
}
