

// -----------------------------------------------------------------------------
//  RESPONSIVE BREAKPOINT MIXINS
// -----------------------------------------------------------------------------
//  Mixins for responsive breakpoints.
//
//  Intended for use with Bootstrap's standard breakpoints,
//  as defined in the following variables:

$screen-sm-min:  768px;
$screen-md-min:  992px;
$screen-lg-min: 1200px;

$screen-xs-max: ($screen-sm-min - 1);  //  767px
$screen-sm-max: ($screen-md-min - 1);  //  991px
$screen-md-max: ($screen-lg-min - 1);  // 1199px


// MIXINS
// ------

// 1200px to infinity
@mixin large {
  @media all and (min-width: $screen-lg-min) { @content; }
}

// 992px to 1199px
@mixin medium {
  @media all and (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
}
@mixin medium-up {
  @media all and (min-width: $screen-md-min) { @content; }
}
@mixin medium-down {
  @media all and (max-width: $screen-md-max) { @content; }
}

// 768px to 991px
@mixin small {
  @media all and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
}
@mixin small-up {
  @media all and (min-width: $screen-sm-min) { @content; }
}
@mixin small-down {
  @media all and (max-width: $screen-sm-max) { @content; }
}

// 0 to 767px
@mixin xsmall {
  @media all and (max-width: $screen-xs-max) { @content; }
}
