.editor-headline {
  padding: 0px;
  margin-bottom: 6px;

  h3 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 22px;
  }

  .action-buttons {
    margin-top: 0px;
    margin-right: 8px;
    
    button {
      margin-left: 8px;
    }
  }

}