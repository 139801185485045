
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';

// MIXINS
@import '../mixins/spacing-mixins';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BUTTONS
 * -----------------------------------------------------------------------------
 *  Overrides the standard Bootstrap buttons.
 *
 */

@mixin button-colors($color, $background-color, $border-color: transparent) {
  background-color: $background-color;
  border-color: $border-color;
  color: $color;
}

@mixin button(
  $color,        $bg-color,
  $color-hover,  $bg-color-hover,
  $color-focus,  $bg-color-focus,
  $color-active, $bg-color-active,
  $border-color:        transparent,
  $border-color-hover:  transparent,
  $border-color-focus:  transparent,
  $border-color-active: transparent
) {
  @include button-colors($color, $bg-color, $border-color);

  &:hover {
    @include button-colors($color-hover, $bg-color-hover, $border-color-hover);
  }

  &:focus,
  &:focus:hover {
    @include button-colors($color-focus, $bg-color-focus, $border-color-focus);
  }

  &.active,
  &.active:hover,
  &.active:focus,
  &:active:focus,
  &:active {
    @include button-colors($color-active, $bg-color-active, $border-color-active);
  }
}


/*
 * BUTTON
 * ------
 */

.btn {
  border-radius: 0;
  font-weight: bold;
  letter-spacing: .5px;
  text-decoration: none;

  // TODO: Consider making one, centrally defined style for outlining.
  // Removed the Chrome style from buttons, and added the shadow style defined
  // for input boxes here as well. Might do the same for links, or, as stated,
  // define it centrally.
  &:active,
  &:focus,
  &:active:focus {
    // Slightly different shadow than for input boxes (not inset)
    outline: 0;
    outline-offset: 0;
  }

  &.disabled,
  &:disabled {
    &,
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      @include button-colors(color(neutral-60), color(neutral-20));
      cursor: default;
    }
  }
}


/*
 * BUTTON STYLES
 * --------------
 */

.btn-primary {
  @include button(
    color(primary-white), color(sea-blue),         // Default colours (text/bg)
    color(primary-white), color(sea-blue-light),   // Hover colours
    color(primary-white), color(sea-blue-dark),    // Focus colours
    color(primary-white), color(sea-blue-darkest)  // Active colours
  );
}

.btn-secondary {
  @include button(
    color(sea-blue-light), color(sky-blue),        // Default colours (text/bg)
    color(sea-blue-light), color(sky-blue-light),  // Hover colours
    color(sea-blue-light), color(cyan-lightest),   // Focus colours
    color(sea-blue), color(cyan-light)             // Active colours
  );
}

.btn-subtle {
  @include button(
    color(sea-blue), color(primary-white),      // Default colours (text/bg)
    color(sea-blue), color(sky-blue-lightest),  // Hover colours
    color(sea-blue), color(sky-blue-lightest),  // Focus colours
    color(sea-blue), color(sky-blue-light),     // Active colours
    color(sky-blue), // Border color, default
    color(sky-blue), // Border color, hover
    color(sky-blue), // Border color, focus
    color(sky-blue)  // Border color, active
  );
  &.disabled,
  &.disabled:hover,
  &.disabled:focus,
  &.disabled:active:focus,
  &.disabled:active {
    @include button-colors(color(neutral-40), color(primary-white), color(neutral-40));
  }
}

.btn-finish {
  @include button(
    color(primary-white), color(land-green),         // Default colours (text/bg)
    color(primary-white), color(land-green-light),   // Hover colours
    color(primary-white), color(land-green-dark),    // Focus colours
    color(primary-white), color(land-green-darkest)  // Active colours
  );
}

.btn-danger {
  @include button(
    color(primary-white), color(red),         // Default colours (text/bg)
    color(primary-white), color(red-light),   // Hover colours
    color(primary-white), color(red-dark),    // Focus colours
    color(primary-white), color(red-darkest)  // Active colours
  );
}


/*
 * BUTTON SIZES
 * ------------
 */

.btn-lg {
  @include spacing(squish-lg);
  font-size: 20px;
}

.btn-md {
  @include spacing(squish-md);
}

.btn-sm {
  @include spacing(squish-sm);
  font-size: 16px;
}

.btn-xs {
  @include spacing(squish-xs);
  font-size: 14px;
}
