.rule-nav {
  width: 18%;
  float: left;

  .rule-nav-item {
    margin-top: 0;

    .missing-data2 {
      display: block;
      font-size: 12px !important;
      color: #ccc;
    }
    
    .dreieck {
      width: 20px;
      height: 30px;
      position: absolute;
      right: 40px;
      
      z-index: 1;
      top: 10px;

      .dreieck_inner {
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 18px solid #ffc5c7;
        position: absolute;
        left: 2px;
        top: 2.4px;
        z-index: 2;
      }
      
      .dreieck_border {
        width: 0;
        height: 0;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 22px solid #c4262e;
        position: absolute;
        left: -1px;
        top: -0.5px;

      }
      
      .dreieck_excl {
        width: 7px;
        height: 26px;
        position: absolute;
        left: 10.5px;
        top: 4px;
        color: #000;
        font-size: 14px;
        z-index: 3;

      }
      
      .missingdata {
        font-size: 9px;
        color: #c4262e;
        position: absolute;
        left: -13px;
        top: 24px;
        width: 50px;
        text-align: center;
        line-height: 1em;
      }
    }
    a {
      width: 100%;
      border-bottom: 1px solid #e5e5e5;
      padding: 15px 35px 14px 10px;
      font-size: 18px;
      color: #003591;
      background-image: url('/static/imgs/img_arrowLeft.png');
      background-repeat: no-repeat;
      background-position: 95% center;
    
      &:hover {
        background-color: #ccecf8;
      }
    
      &.active {
        color: #fff;
        background-color: #008fda;
        font-weight: bold;
        background-image: url('/static/imgs/img_arrowLeft_sel.png');
      }
    }
  }
} 
