
// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';

// MIXINS
@import '../mixins/rwd-mixins';
@import '../mixins/spacing-mixins';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BLOCKS
 * -----------------------------------------------------------------------------
 *
 */

.block {
  @include spacing-responsive(inset-md);
  @include contain-children;
  @include spacing(stack-md);
  background-color: color(neutral-05);
  display: block;
  position: relative;

  &.block-enclose {
    background-color: transparent;
    padding: 0;
  }

  &.block-bordered {
    @include spacing-responsive(inset-md, bordered);
    background-color: transparent;
  }

  &.block-framed {
    @include spacing-responsive(inset-md, framed);
    background-color: transparent;
  }

  &.block-inverse {
    background-color: color(neutral-80);
    color: color(primary-white);
  }

  &.block-transparent {
    background-color: transparent;
  }

}
