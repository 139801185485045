
// RELATIVE PATH
$relative-path: '../../' !default;

// SETTINGS
@import '../settings/dnvgl-color-settings';
@import '../settings/spacing-variables';
@import '../settings/text-variables';

// MIXINS
@import '../mixins/spacing-mixins';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  FORMS
 * -----------------------------------------------------------------------------
 *  Overrides default Bootstrap form styling.
 *
 */

// Common settings
$input-border-color: color(neutral-40);


/**
 *
 *  FORM CONTROLS AND LABELS
 *  ------------------------
 *  Overrides Bootstrap's form control class.
 */

 .form-group {
   margin-bottom: 1rem;
 }

.form-control {
  border-color: $input-border-color;
  border-radius: 0;
  color: color(primary-black);
  height: inherit;
}

.form-control:focus {
  border-color: $input-border-color;
  // TODO: Define focus shadow as mixin?
  box-shadow: inset 0 1px 1px color(neutral-10), 0 0 8px color(sky-blue);
}

label {
  font-weight: bold;
  line-height: $dnvgl-line-height-condensed;
  @include spacing(stack-xxs);
}


/**
 *
 *  FORM VALIDATION
 *  ---------------
 */

// TODO: Should be rewritten to use standard Bootstrap validaton markup:
// http://getbootstrap.com/css/#forms-control-validation

.form-control-error {
  border-color: color(red);
  border-width: 1px;
}

.form-control-error:focus {
  border-color: color(red);
  // TODO: Define focus shadow as mixin?
  box-shadow: inset 0 1px 1px color(neutral-10), 0 0 8px color(red-lightest);
}

.form-control-success {
  border-color: color(land-green);
}

.form-control-success:focus {
  border-color: color(land-green);
  // TODO: Define focus shadow as mixin?
  box-shadow: inset 0 1px 1px color(neutral-10), 0 0 8px color(land-green-lightest);
}


/**
 *
 *  CHECKBOXES AND RADIO BUTTONS
 *  ----------------------------
 */

// Overrides standard Bootstrap placement of checkboxes and
// radio buttons to compensate for changed font-size and line-height.
// TODO: Test best default across browsers, then remove this definition.
// input[type="radio"] {
//  margin: 2px 0 0;
// }
// input[type="checkbox"] {
//  margin: 3px 0 0;
// }

.radio,
.checkbox {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: normal;
}

// Pull consecutive checkboxes / radio buttons together
.checkbox + .checkbox,
.radio + .radio {
  margin-top: -.5rem;
}

// Disabled checkboxes and radio buttons
.radio-disabled,
.checkbox-disabled {
  input {
    cursor: not-allowed;
    filter: grayscale(100%);
  }
}

// Disabled checkboxes and radio buttons
// NOTE: The styling of radio boxes  with an input[type=hidden] squeezed
// in before the label is to support standard Angular.JS behaviour.
label.radio-disabled,
label.checkbox-disabled,
.checkbox-disabled label,
.radio-disabled label,
input[type=checkbox]:disabled + label,
input[type=checkbox]:disabled + input[type=hidden] + label,
input[type=radio]:disabled + label,
input[type=radio]:disabled + input[type=hidden] + label {
  cursor: not-allowed;
  filter: grayscale(100%);
  opacity: .4;
}


/**
 *
 *  STYLED CHECKBOXES AND RADIOBOXES
 *  --------------------------------
 *  Provides styled alternatives to radio boxes and check boxes.
 */


/**
 * Base style for styled radio boxes and checkboxes
 */

.radio-styled,
.checkbox-styled {

  // Hide default radiobox / checkbox to allow for styling
  input[type=radio],
  input[type=radio] + input[type=hidden],
  input[type=checkbox],
  input[type=checkbox] + input[type=hidden] {
    // Move input offscreen while ensuring it is still accessible.
    left: -99999px;
    position: absolute;
  }

  // Style labels to contain radio button / checkbox image
  label {
    background-position: 0 -1px;
    background-repeat: no-repeat;
    background-size: 23px 22px;
    cursor: pointer;
    // Make it an inline-block to allow for minimum height
    display: inline-block;
    font-weight: normal;
  }

  // Disabled state
  input:disabled + label,
  input:disabled + input[type=hidden] + label {
    cursor: default;
    filter: grayscale(100%);
  }

}


/**
 * Styled checkboxes
 */

.checkbox-styled {

  // Style labels to contain checkbox image
  label {
    // Add padding to make space for icon.
    padding-left: 25px;
  }

  // Preloads SVGs in order to avoid delays when they are used,
  // for example on hover over the checkbox or state change.
  &::after {
    content:
      url($relative-path + 'images/forms/checkbox-unchecked.svg')
      url($relative-path + 'images/forms/checkbox-unchecked-hover.svg')
      url($relative-path + 'images/forms/checkbox-checked.svg');
    display: none;
  }

  // NOTE: The styling of radio boxes  with an input[type=hidden] squeezed
  // in before the label is to support standard Angular.JS behaviour.
  // (See the three definitions below.)

  // Checkbox is unchecked
  input[type=checkbox]:not(:checked) + label,
  input[type=checkbox]:not(:checked) + input[type=hidden] + label {
    background-image: url($relative-path + 'images/forms/checkbox-unchecked.svg');
  }

  // Checkbox is unchecked and hovered
  input[type=checkbox]:not(:checked):hover + label,
  input[type=checkbox]:not(:checked):hover + label,
  input[type=checkbox]:not(:checked) + input[type=hidden] + label:hover,
  input[type=checkbox]:not(:checked) + input[type=hidden] + label:hover {
    background-image: url($relative-path + 'images/forms/checkbox-unchecked-hover.svg');
  }

  // Checkbox is checked
  input[type=checkbox]:checked + label,
  input[type=checkbox]:checked + input[type=hidden] + label {
    background-image: url($relative-path + 'images/forms/checkbox-checked.svg');
  }

}


/**
 * Styled radio buttons
 */

.radio-styled {

  // Style labels to contain checkbox image
  label {
    // Rounded edge of radio button will protude slightly to the left.
    margin-left: -1px;
    // Add padding to make space for icon.
    padding-left: 25px;
    // Turn off max-width:100% as it chrashes somewhat with the -1px left margin
    max-width: none;
  }

  // Preloads SVGs in order to avoid delays when they are used,
  // for example on hover over the checkbox or state change.
  &::after {
    content:
      url($relative-path + 'images/forms/radio-button-unchecked.svg')
      url($relative-path + 'images/forms/radio-button-unchecked-hover.svg')
      url($relative-path + 'images/forms/radio-button-checked.svg');
    display: none;
  }

  // NOTE: The styling of radio boxes  with an input[type=hidden] squeezed
  // in before the label is to support standard Angular.JS behaviour.
  // (See the three definitions below.)

  // Radiobox is unchecked
  input[type=radio]:not(:checked) + label,
  input[type=radio]:not(:checked) + input[type=hidden] + label {
    background-image: url($relative-path + 'images/forms/radio-button-unchecked.svg');
  }

  // Radiobox is unchecked and hovered
  input[type=radio]:not(:checked):hover + label,
  input[type=radio]:not(:checked):hover + label,
  input[type=radio]:not(:checked) + input[type=hidden] + label:hover,
  input[type=radio]:not(:checked) + input[type=hidden] + label:hover {
    background-image: url($relative-path + 'images/forms/radio-button-unchecked-hover.svg');
  }

  // Radiobox is checked
  input[type=radio]:checked + label,
  input[type=radio]:checked + input[type=hidden] + label {
    background-image: url($relative-path + 'images/forms/radio-button-checked.svg');
  }

}


/**
 * Inline checkboxes and radio buttons
 * Moves margin to the right side to better support line breaking on mobile.
 */

// Wrapping on smaller screens was not good - swiched sides of margin
.radio-inline,
.checkbox-inline {
  margin-right: 0.75rem;
}

// Have to include this to override default Bootstrap styling
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 0;
}

.radio-styled.radio-inline,
.checkbox-styled.checkbox-inline {
  margin-right: 1rem;
  padding: 0;
}


/**
 * FORM SECTIONS
 * -------------
 * Sections for wrapping several form fields,
 * for example inlined checkboxes or radio buttons.
 */

fieldset {
  @include spacing(stack-line-height);
}

fieldset > legend {
  border: 0;
  // padding-bottom: 0.25rem;
  // border-bottom: 1px solid color(neutral-10);
  color: color(primary-black);
  display: block;
  font-size: 1rem;
  font-weight: 700;
  line-height: $dnvgl-line-height-condensed;
  margin-bottom: .75rem;
  width: 100%;
}


/**
 *
 *  SELECT BOX
 *  ----------
 */

.select-wrapper {
  display: block;
  position: relative;
}

.select-wrapper::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid color(primary-black);
  content: '';
  height: 0;
  pointer-events: none;
  position: absolute;
  right: .75 * $font-size-base;
  top: ( $font-size-base - 1px);
  width: 0;
}

// Select box will also have the '.form-control' class, to
// inherit common Bootstrap properties for input fields.
.select-wrapper > select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: color(primary-white);
  border: 1px solid $input-border-color;
  border-radius: 0;
  font-size: $font-size-base;
  padding-right: (2 * $font-size-base );
  width: 100%;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    // TODO: Define focus shadow as mixin?
    box-shadow: inset 0 1px 1px color(neutral-10), 0 0 8px color(sky-blue);
    // TODO: This shadow definition has outline defined, why not the others?
    outline: 0;
    outline-offset: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 color(primary-black);
  }

  &::-ms-value {
    background: none;
    color: color(primary-black);
  }
}

@mixin small-up {
  .form-inline .select-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }
}


/**
 *
 *  INPUT PLACEHOLDERS
 *  ------------------
 *  Styles color of input box placeholders.
 */

// TODO: Replace with standard and let AutoPrefixer handle it?

::-webkit-input-placeholder {
  color: color(neutral-60);
}

::-moz-placeholder {
  color: color(neutral-60);
}

:-ms-input-placeholder {
  color: color(neutral-60);
}


/**
 *
 *  TEXT AREA
 *  ---------
 */

textarea {
  resize: none;
}
