.alertify {
  .dialog > div {
    padding: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    button {
      font-weight: bold;
      border-radius: 0px !important;
  }
    
    .cancel {
      border: 1px solid #ccc !important;
  }

  .ok {
    border: 1px solid #003591 !important;
    background-color: #003591 !important;
    color: #fff !important;;

  &:hover {
    background-color: #1f51a8 !important;
    border-color: transparent !important;
    color: #fff !important;
}
}


    .msg {
      padding: 0px;
      padding: 0px 0px 8px;
      font-weight: 600;
  }
  }

  z-index: 10;
}

.alertify-logs {
  color: #fff;
  .success {
   background-color: #36842d;
   padding: 15px 20px;
  }

  .error {
    background-color: #c4262e;
    padding: 15px 20px;
   }
}

.rule-content {
     top: -20px;
     position: relative;
}

.rule-text {
  width: 73%;
  font-size: 18px;
  float: left;
  margin-top: 10px;
}

.rule-projects {
  width: 27%;
  float: left;
  margin-top: 10px;
}

@media (max-width: 768px){
  .itembox {
    width: 90% !important;
  }
}

@media (max-width: 850px){
  .rule-projects {
    width: 37%;
  }
  .rule-text {
    width: 60% !important;
  }
}

@media (min-width: 992px){
  .container {
      width: 95%;
  }
}

@media (max-width: 992px){
 .col-md-10 {
    width: 83.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }
}

@media (max-width: 1165px){
  .rule-text {
    width: 70%;
  }
  .container {
    width: 95%;
}
}

@media (max-width: 1245px){
  .rule-nav .rule-nav-item a {
    font-size: 16px !important;
  }

  .rule-text {
    font-size: 16px;
  }

  .rule-saves .savedProjects_date {
    font-size: 12px !important;
  }

  .rule-nav {
    width: 25% !important;
  }

  .rule-body {
    width: 75% !important;
  }
}

@media (max-width: 1095px){

  h2, .h2 { 
    font-size: 1.7rem;
  }
  
  h3, .h3 { 
    font-size: 1.5rem; 
  }
  
  h4, .h4 { 
    font-size: 1.3rem; 
  }
  
  h5, .h5 { 
    font-size: 1.2rem; 
  }

}



@media (min-width: 1200px){
  .container {
      width: 90%;
  }
}



@media (min-width: 1600px){
  .container {
      width: 80%;
  }
}



