.home-content {
  padding-left: 0;
  padding-right: 0;
  
  .headline {
    padding: 0px 0px 10px;
    line-height: 1.1;
    border-bottom: 1px solid #ccc;
    min-height: 40px;
  }

  .headline-small {
    padding-top: 20px;
    line-height: 26px;
    margin-top: 0px;
  }

}