
// SETTINGS
@import '../settings/dnvgl-color-settings';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  BLOCKS
 * -----------------------------------------------------------------------------
 *
 */


// Overrides
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-item:last-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.list-group-item {
  padding: 1rem;
  border: 0;
  border-bottom: 1px solid color(neutral-10);
  border-top: 1px solid color(neutral-10);
}

// Custom
.list-group-navigation {
  .list-group-item {
    border: 0;
    border-bottom: 1px solid color(neutral-10);
    margin: 0;
    padding: 0;
  }

  .list-group-item:first-child {
    border-top: 1px solid color(neutral-10);
  }

  a {
    color: inherit;
    display: block;
    padding: 1rem 2rem;  // TODO: Redo to match spacing system??!
    position: relative;

    &::before {
      background: transparent;
      bottom: .25rem;
      content: '';
      left: 0;
      position: absolute;
      top: .25rem;
      width: 9px;
    }
  }

  a:hover,
  a:focus {
    box-shadow: none;
    color: inherit;
    font-weight: 700;
    outline: 0;
    text-decoration: none;

    &::before {
      background: color(sky-blue);
    }
  }

  .active,
  .active:hover {
    background: transparent;
    border-bottom: 1px solid color(neutral-10);
    color: inherit;
    font-weight: 700;
  }

  .active a::before {
    background: color(dark-blue);
  }

  .active a:hover::before,
  .active a:focus::before {
    background: color(sky-blue);
  }
}

.list-group-condensed {
  .list-group-item {
    padding: 5px;
  }
}

.list-group-bordered {
  .list-group-item {
    border: 1px solid color(neutral-10);
  }
}
