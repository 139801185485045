
// MIXINS
@import '../mixins/rwd-mixins';


/**
 *
 *
 * -----------------------------------------------------------------------------
 *  LOGO
 * -----------------------------------------------------------------------------
 *  The DNV GL logo and variations of it in different sizes.
 *
 */

// /**
//  * Logo Breakpoints
//  */
// $logo-break-lg: 992px;
// $logo-break-md: 768px;
// $logo-break-sm: 576px;


/**
 * Logo size definitions
 */

// Default image dimensions
$logo-image-width:   4200px;  // Full logo width including huge bleed
$logo-image-height:    67px;  // Logo height
$logo-wordmark-width: 127px;  // DNV.GL wordmark width - controls positioning
$logo-offset:          17px;  // Minimum spacing around logo

// Aspect ratio of the logo image (including huge bleed),
// used to calculate the individual logo sizes
$logo-aspect-ratio:           $logo-image-width / $logo-image-height;
$logo-wordmark-aspect-ratio:  $logo-wordmark-width / $logo-image-height;
$logo-offset-aspect-ratio:    $logo-offset / $logo-image-height;

// Logo sizes specified by height
$logo-image-height-lg: 67px;
$logo-image-height-md: 57px;
$logo-image-height-sm: 47px;
$logo-image-height-xs: 37px;



/**
 *
 *  LOGO MIXIN
 *  -----------
 *  Used to calculate different sizes and versions of the logo.
 */

@mixin logo-size($height) {
  $width:          $height * $logo-aspect-ratio;
  $wordmark-width: $height * $logo-wordmark-aspect-ratio;
  $offset-width:   $height * $logo-offset-aspect-ratio;

  height: $height;
  width: $wordmark-width;

  &.logo-indented {
    width: $wordmark-width * 2;
  }

  &.logo-offset {
    margin: $offset-width $offset-width $offset-width 0;
  }

  .logo-image {
    height: $height;
    width: $width;
  }

}



/**
 *
 *  STANDARD LOGO
 *  -------------
 */

.logo {
  // Defaults to medium logo size
  @include logo-size($logo-image-height-md);
  display: block;
  position: relative;

  // Logo image is right-aligned inside logo box,
  // which is sized to match the wordmark-width.
  // Logo horizon (stripes) will then bleed to the left.
  .logo-image {
    position: absolute;
    right: 0;
  }

  // Logo sizes
  &.logo-lg { @include logo-size($logo-image-height-lg); }
  // &.logo-md { @include logo-size($logo-image-height-md); }  // Default
  &.logo-sm { @include logo-size($logo-image-height-sm); }
  &.logo-xs { @include logo-size($logo-image-height-xs); }

}



/**
 *
 *  RESPONSIVE LOGO
 *  ---------------
 */

.logo.logo-responsive {

  // @include xsmall {
  //   &,
  //   &.logo-sm,
  //   &.logo-md {
  //     @include logo-size($logo-image-height-xs);
  //   }
  //   &.logo-lg {
  //     @include logo-size($logo-image-height-sm);
  //   }
  // }
  //
  // // Medium logo (default)
  // @include small {
  //   &,
  //   &.logo-md {
  //     @include logo-size($logo-image-height-sm);
  //   }
  //   &.logo-lg {
  //     @include logo-size($logo-image-height-md);
  //   }
  // }
  //
  // // Large logo
  // @include medium {
  //   &.logo-lg {
  //     @include logo-size($logo-image-height-md);
  //   }
  // }


  // Small logo
  &.logo-sm {
    @include small-down { @include logo-size($logo-image-height-xs); }
  }

  // Medium logo (default)
  &,
  &.logo-md {
    @include xsmall { @include logo-size($logo-image-height-xs); }
    @include small { @include logo-size($logo-image-height-sm); }
  }

  // Large logo
  &.logo-lg {
    @include xsmall { @include logo-size($logo-image-height-sm); }
    @include small { @include logo-size($logo-image-height-md); }
  }

}
