.rules-page {
  overflow-y: scroll;
  height: 100%;
  // border: 1px solid;
}

.rulesDate {
  width: 100px;
}

.fontBold {
  font-weight: 600;
}

.ruleRef {
  margin-bottom: 10px;
}

.front-img > img {
    width: 60px;
    height: auto;
  }

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;

  .panel-heading {
    color: #333333;
    background-color: #fff;
    border: none;
    padding: 0;
    border-radius: 0px;
  }

  .panel-body {
    padding: 0px;
  }
}

.table-condensed > tbody > tr > td, .table-condensed > tbody > tr > th {
    padding: 10px 5px 0px;
    border-bottom: 1px solid #ddd;
    border-top: none;

    div > button {
    margin-bottom: 10px;
    margin-right: 5px;

  }
}




